import React from "react"
import Layout from "../layouts/layout"
import Seo from "../components/seo"

import { CategoriesProvider } from "../contexts/categoriesContext"

import Card, { CardContent, CardHeader } from "../components/Card"
import CategoryTopicsForm from "../components/Forms/CategoryAndTopics"
import CategoriesTopicsList from "../components/List/categoriesTopicsList"
import SubscriptionForm from "../components/Forms/Subscription"
import Alert from "../components/Alert"
import Loading from "../components/Loader"
import Success from "../components/Response/success"
import Fail from "../components/Response/fail"
import { useSubscription } from "../contexts/subscribeContext"

// Page
function SignupPage() {
  return (
    <Layout page="signup">
      <Card>
        <CardHeader title="Welcome to Newsletter Subscription">
          Here you can subscribe to topics of interest to receive weekly emails
          in your inbox as a digest when new content is available.
        </CardHeader>
        <CardContent>
          <Newsletter />
        </CardContent>
      </Card>
    </Layout>
  )
}

// Newsletter Subscription
const Newsletter = () => {
  const {
    pageLoading,
    submitted,
    response,
    responseText,
    loading,
    categoriesAndTopics,
    handleAdd,
    handleRemove,
    isSelectAll,
    clearAll,
    toggleSelectAll,
    recaptchaRef,
    handleSubscription,
    handleVerify,
    submitButtonVisible,
    handleEmailChange,
    isDisabled,
    isVerified,
    email,
  } = useSubscription()

  const states = {
    categoriesAndTopics,
    email,
    isVerified,
    isDisabled,
    submitButtonVisible,
  }

  const handlers = {
    handleSubscription,
    handleVerify,
    handleEmailChange,
  }

  if (submitted && response === "success") {
    return <Success message={responseText} />
  } else if (submitted && response === "fail") {
    return <Fail />
  } else {
    return (
      <MainForm isLoading={pageLoading} loading={loading}>
        <CategoriesProvider>
          <CategoryTopicsForm
            categoriesAndTopics={categoriesAndTopics}
            handleAdd={handleAdd}
            isSelectAll={isSelectAll}
            clearAll={clearAll}
            toggleSelectAll={toggleSelectAll}
            message="All your existing subscriptions will be replaced by all categories & topics"
          />
        </CategoriesProvider>

        <CategoriesTopicsList
          loading={false}
          categoriesAndTopics={categoriesAndTopics}
          isSelectAll={isSelectAll}
          handleRemove={handleRemove}
        />

        <Alert>
          You will be notified as a weekly digest when new summaries of your
          interest are available
        </Alert>

        <SubscriptionForm
          action="subscribe"
          recaptchaRef={recaptchaRef}
          states={states}
          handlers={handlers}
        />
      </MainForm>
    )
  }
}

// Main Form
const MainForm = ({ isLoading, children }) => {
  return (
    <>
      <Loading isLoading={isLoading} />
      {children}
    </>
  )
}

// Seo
export const Head = () => <Seo title="New Home" />

export default SignupPage
